"use client";
/**
 * ^ Dynamic imports (as of Next.JS 14.2) only get extracted to separate client
 * bundles from *client* components, but not when used in server components.
 *
 * It would probably be desirable to make this a server component otherwise, so
 * we should keep an eye on this in future releases.
 */

import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import dynamic from "next/dynamic";
import { notNullish } from "../../helpers/notNullish";
import { Block } from "../../helpers/PageConfig";
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer";
import {
  isBlocksQaArticle,
  isImage,
  isBlocksQaStage,
} from "../../helpers/typeguards";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { PersonProfile } from "../../__generated__/crepo-types";
import { notNull } from "../../helpers/notNull";
import type { ArticlePanel } from "../blocks/article/article";
import { getTimeOfDayForChapter } from "../blocks/cartoons-occasion-stack/getTimeOfDayForChapter";
import {
  PhaseImageType,
  PhasePanelColumnsType,
  PhaseSideImageColumnsType,
  PhaseTextType,
} from "../blocks/lifecycle/lifecycle.types";
import {
  contentfulPhaseTextToReactNode,
  contentfulRichTextToSideImageColumns,
} from "../blocks/lifecycle/lifecycle-phase/lifecycle-phase";
import Markdown from "markdown-to-jsx";
import { Fragment } from "react";

const AnimatedProductTeaserCard = dynamic(() =>
  import(
    "../blocks/animated-product-teaser-card/animated-product-teaser-card"
  ).then((mod) => mod.AnimatedProductTeaserCard),
);
const Article = dynamic(() =>
  import("../blocks/article/article").then((mod) => mod.Article),
);
const ArticleStage = dynamic(() =>
  import("../blocks/article-stage/article-stage").then(
    (mod) => mod.ArticleStage,
  ),
);
const AthleteQuoteStack = dynamic(() =>
  import("../blocks/athlete-quote-stack/athlete-quote-stack").then(
    (mod) => mod.AthleteQuoteStack,
  ),
);
const BenefitsList = dynamic(() =>
  import("../blocks/benefits-list/benefits-list").then(
    (mod) => mod.BenefitsList,
  ),
);
const CenteredProductHeroCan = dynamic(() =>
  import("../blocks/centered-product-hero-can/centered-product-hero-can").then(
    (mod) => mod.CenteredProductHeroCan,
  ),
);
const ChatAssistantTeaser = dynamic(() =>
  import("../blocks/chat-assistant-teaser/chat-assistant-teaser").then(
    (mod) => mod.ChatAssistantTeaser,
  ),
);
const ContactAssistantStage = dynamic(() =>
  import("../blocks/contact-assistant-stage/contact-assistant-stage").then(
    (mod) => mod.ContactAssistantStage,
  ),
);
const CompanyStage = dynamic(() =>
  import("../blocks/company-stage/company-stage").then(
    (mod) => mod.CompanyStage,
  ),
);
const CompanyTeaser = dynamic(() =>
  import("../blocks/company-teaser/company-teaser").then(
    (mod) => mod.CompanyTeaser,
  ),
);
const ContactForm = dynamic(() =>
  import("../blocks/contact-form/contact-form").then((mod) => mod.ContactForm),
);
const ContactFormStage = dynamic(() =>
  import("../blocks/contact-form-stage/contact-form-stage").then(
    (mod) => mod.ContactFormStage,
  ),
);
const CustomScript = dynamic(() =>
  import("../blocks/custom-script/custom-script").then(
    (mod) => mod.CustomScript,
  ),
);
const DisclosureList = dynamic(() =>
  import("../blocks/disclosure-list/disclosure-list").then(
    (mod) => mod.DisclosureList,
  ),
);
const ExperienceWorb = dynamic(() =>
  import("../blocks/experience-worb/experience-worb").then(
    (mod) => mod.ExperienceWorb,
  ),
);
const FlyingChocolateBanner = dynamic(() =>
  import("../blocks/flying-chocolate-banner/flying-chocolate-banner").then(
    (mod) => mod.FlyingChocolateBanner,
  ),
);
const FlyingChocolateNutritionFacts = dynamic(() =>
  import(
    "../blocks/flying-chocolate-nutrition-facts/flying-chocolate-nutrition-facts"
  ).then((mod) => mod.FlyingChocolateNutritionFacts),
);
const FlyingChocolateStage = dynamic(() =>
  import("../blocks/flying-chocolate-stage/flying-chocolate-stage").then(
    (mod) => mod.FlyingChocolateStage,
  ),
);
const GenericSection = dynamic(() =>
  import("../blocks/generic-section/generic-section").then(
    (mod) => mod.GenericSection,
  ),
);
const GenericStage = dynamic(() =>
  import("../blocks/generic-stage/generic-stage").then(
    (mod) => mod.GenericStage,
  ),
);
const HighlightIntro = dynamic(() =>
  import("../blocks/highlight-intro/highlight-intro").then(
    (mod) => mod.HighlightIntro,
  ),
);
const HighlightProgressCarousel = dynamic(() =>
  import(
    "../blocks/highlight-progress-carousel/highlight-progress-carousel"
  ).then((mod) => mod.HighlightProgressCarousel),
);
const HighlightsStage = dynamic(() =>
  import("../blocks/highlights-stage/highlights-stage").then(
    (mod) => mod.HighlightsStage,
  ),
);
const ImageGallery = dynamic(() =>
  import("../blocks/image-gallery/image-gallery").then(
    (mod) => mod.ImageGallery,
  ),
);
const Ingredient = dynamic(() =>
  import("../blocks/ingredient/ingredient").then((mod) => mod.Ingredient),
);
const IngredientsStage = dynamic(() =>
  import("../blocks/ingredients-stage/ingredients-stage").then(
    (mod) => mod.IngredientsStage,
  ),
);
const LifecycleTeaser = dynamic(() =>
  import("../blocks/lifecycle-teaser/lifecycle-teaser").then(
    (mod) => mod.LifecycleTeaser,
  ),
);
const MissionStatement = dynamic(() =>
  import("../blocks/mission-statement/mission-statement").then(
    (mod) => mod.MissionStatement,
  ),
);
const MoodboardTiles = dynamic(() =>
  import("../blocks/moodboard-tiles/moodboard-tiles").then(
    (mod) => mod.MoodboardTiles,
  ),
);
const MomentsStage = dynamic(() =>
  import("../blocks/moments-stage/moments-stage").then(
    (mod) => mod.MomentsStage,
  ),
);
const NewLifecycle = dynamic(() =>
  import("../blocks/lifecycle/lifecycle").then((mod) => mod.NewLifecycle),
);
const OccasionStack = dynamic(() =>
  import("../blocks/occasion-stack/occasion-stack").then(
    (mod) => mod.OccasionStack,
  ),
);
const OrganicsTeaserCard = dynamic(() =>
  import("../blocks/organics-teaser-card/organics-teaser-card").then(
    (mod) => mod.OrganicsTeaserCard,
  ),
);
const PhotoTiles = dynamic(() =>
  import("../blocks/photo-tiles/photo-tiles").then((mod) => mod.PhotoTiles),
);
const Placeholder = dynamic(() =>
  import("../blocks/placeholder/placeholder").then((mod) => mod.Placeholder),
);
const ProductTeaserCard = dynamic(() =>
  import("../blocks/product-teaser-card/product-teaser-card").then(
    (mod) => mod.ProductTeaserCard,
  ),
);
const NutritionInformation = dynamic(() =>
  import("../blocks/nutrition-information/nutrition-information").then(
    (mod) => mod.NutritionInformation,
  ),
);
const ProductDescription = dynamic(() =>
  import("../blocks/product-description/product-description").then(
    (mod) => mod.ProductDescription,
  ),
);
const ProductHeroCan = dynamic(() =>
  import("../blocks/product-hero-can/product-hero-can").then(
    (mod) => mod.ProductHeroCan,
  ),
);
const ProductHighlightPanel = dynamic(() =>
  import("../blocks/product-highlight-panel/product-highlight-panel").then(
    (mod) => mod.ProductHighlightPanel,
  ),
);
const ProductRail = dynamic(() =>
  import("../blocks/product-rail/product-rail").then((mod) => mod.ProductRail),
);
const ProductStage = dynamic(() =>
  import("../blocks/product-stage/product-stage").then(
    (mod) => mod.ProductStage,
  ),
);
const QaArticle = dynamic(() =>
  import("../blocks/QaArticle/QaArticle").then((mod) => mod.QaArticle),
);
const QaMoreTeaser = dynamic(() =>
  import("../blocks/QaMoreTeaser/QaMoreTeaser").then((mod) => mod.QaMoreTeaser),
);
const QaStage = dynamic(() =>
  import("../blocks/QaStage/QaStage").then((mod) => mod.QaStage),
);
const QaSummary = dynamic(() =>
  import("../blocks/QaSummary/QaSummary").then((mod) => mod.QaSummary),
);
const RecipeSlider = dynamic(() =>
  import("../blocks/recipe-slider/recipe-slider").then(
    (mod) => mod.RecipeSlider,
  ),
);
const Quote = dynamic(() =>
  import("../blocks/quote/quote").then((mod) => mod.Quote),
);
const SpeakUpCta = dynamic(() =>
  import("../blocks/SpeakUpCta/SpeakUpCta").then((mod) => mod.SpeakUpCta),
);

const StepsCarousel = dynamic(() =>
  import("../blocks/steps-carousel/steps-carousel").then(
    (mod) => mod.StepsCarousel,
  ),
);
const SupportCta = dynamic(() =>
  import("../blocks/support-cta/support-cta").then((mod) => mod.SupportCta),
);

const SustainabilityQuiz = dynamic(() =>
  import("../blocks/SustainabilityQuiz/SustainabilityQuiz").then(
    (mod) => mod.SustainabilityQuiz,
  ),
);
const UsefulSourcesRail = dynamic(() =>
  import("../blocks/useful-sources-rail/useful-sources-rail").then(
    (mod) => mod.UsefulSourcesRail,
  ),
);
const Video = dynamic(() =>
  import("../blocks/video/video").then((mod) => mod.Video),
);
const WorbCarousel = dynamic(() =>
  import("../blocks/worb-carousel/worb-carousel").then(
    (mod) => mod.WorbCarousel,
  ),
);
const WorbRail = dynamic(() =>
  import("../blocks/worb-rail/worb-rail").then((mod) => mod.WorbRail),
);
const YearMap = dynamic(() =>
  import("../blocks/year-map/year-map").then((mod) => mod.YearMap),
);
const StayTuned = dynamic(() =>
  import("../blocks/stay-tuned/stay-tuned").then((mod) => mod.StayTuned),
);
const InteractiveHero = dynamic(() =>
  import("../blocks/interactive-hero/interactive-hero").then(
    (mod) => mod.InteractiveHero,
  ),
);
const CartoonsOccasionStack = dynamic(() =>
  import("../blocks/cartoons-occasion-stack/cartoons-occasion-stack").then(
    (mod) => mod.CartoonsOccasionStack,
  ),
);
const InteractiveHeroLifecycleTeaser = dynamic(() =>
  import(
    "../blocks/interactive-hero-lifecycle-teaser/interactive-hero-lifecycle-teaser"
  ).then((mod) => mod.InteractiveHeroLifecycleTeaser),
);

export const GenericBlock = ({
  block,
  hasInteractiveHero,
  scrollTransitionsEnabled,
  blocks,
}: {
  block: Block;
  hasInteractiveHero: boolean;
  scrollTransitionsEnabled: boolean;
  blocks: Block[];
}) => {
  switch (block.__typename) {
    case "BlocksAnimatedProductTeaserCard":
      return (
        <AnimatedProductTeaserCard
          backgroundColor={block.backgroundColor}
          backgroundImageUrl={block.backgroundImage?.url ?? null}
          linkUrl={block.linkUrl}
          logoHeadingUrl={block.logoHeading?.url ?? null}
          logoHeadingAlt={block.logoHeading?.description ?? null}
          name={block.name}
          title={block.title}
          disclaimer={block.disclaimer}
          description={block.description}
          centerDisclaimer={block.centerDisclaimer}
          linkText={block.linkText}
          animationStartImageUrl={block.animationStartImage?.url ?? null}
          animationStartImageAlt={
            block.animationStartImage?.description ?? null
          }
          animationEndImageUrl={block.animationEndImage?.url ?? null}
          animationEndImageAlt={block.animationEndImage?.description ?? null}
        />
      );
    case "BlocksArticle":
      return (
        <Article
          panels={
            block.panelsCollection?.items
              .filter(notNullish)
              .map<ArticlePanel>((panel) => {
                switch (panel.__typename) {
                  case "GenericRichText": {
                    return {
                      kind: "rich-text",
                      content:
                        panel.text?.json &&
                        documentToReactComponents(panel.text.json),
                    };
                  }
                  case "BlocksImageGallery": {
                    return {
                      kind: "image-gallery",
                      title: panel.title,
                      variant: panel.variant,
                      items:
                        panel.mediaCollection?.items.map((item) => ({
                          imageUrl: item?.image?.url ?? null,
                          caption: item?.caption ?? null,
                        })) ?? [],
                    };
                  }
                  case "BlocksVideo": {
                    return {
                      kind: "video",
                      playerId: panel.videoPlayerId,
                    };
                  }
                }
              }) ?? []
          }
        />
      );
    case "BlocksArticleStage":
      return (
        <ArticleStage
          kicker={block.kicker}
          title={block.title}
          subtitle={block.subtitle}
          backgroundImageUrl={block.backgroundImage?.url ?? null}
        />
      );
    case "BlocksAthleteQuoteStack": {
      return (
        <AthleteQuoteStack
          cards={block.dotcomContent
            .map((card) => {
              const productRelatedImage =
                card.productRelatedImage && isImage(card.productRelatedImage)
                  ? card.productRelatedImage
                  : undefined;

              const headerImage =
                card.headerMedia && isImage(card.headerMedia)
                  ? card.headerMedia
                  : undefined;

              const media = productRelatedImage || headerImage;

              const mediaUrl =
                media?.imageEssence?.__typename === "CloudinaryImage"
                  ? media.imageEssence.imageURL
                  : media?.imageSrc;

              if (!mediaUrl) {
                return null;
              }

              return {
                id: card.id,
                disciplines: card.disciplines?.filter(notNullish) ?? [],
                productRelatedQuote: card.productRelatedQuote?.text ?? null,
                title: card.title?.text ?? null,
                url: card.url?.href ?? null,
                mediaUrl: mediaUrl,
                alt: media?.title?.text ?? null,
              };
            })
            .filter(notNullish)}
          animated={scrollTransitionsEnabled}
        />
      );
    }
    case "BlocksBenefitsList":
      return (
        <BenefitsList
          imageUrl={block.image?.url ?? null}
          videoUrl={block.video?.url ?? null}
          benefitsCollection={
            block.benefitsCollection?.items?.map((item) => {
              return {
                title: item?.title ?? null,
                text: item?.text?.json
                  ? documentToReactComponents(item.text.json)
                  : null,
              };
            }) ?? null
          }
        />
      );
    case "BlocksCartoonsOccasionStack": {
      return (
        <CartoonsOccasionStack
          title={block.title}
          description={block.description}
          buttonText={block.buttonText}
          items={block.dotcomContent.map((occasion) => {
            const image = occasion.featuredMedia?.filter(notNull).find(isImage);

            const imageUrl =
              (image?.imageEssence?.__typename === "CloudinaryImage"
                ? image.imageEssence.imageURL
                : image?.imageSrc) ?? null;

            // Extract day_segment
            const crepoDaySegment =
              occasion.customProperties?.elements?.find(
                (item) => item?.key === "day_segment",
              )?.value?.text ?? "morning1";

            // Extract order if available
            const crepoOrder =
              occasion.customProperties?.elements?.find(
                (item) => item?.key === "order",
              )?.value?.text ?? null;

            return {
              imageUrl,
              crepoDaySegment,
              crepoOrder,
              description: occasion.teaser?.text ?? "",
              id: occasion.id,
              title: occasion.title?.text ?? null,
              timeOfDay: getTimeOfDayForChapter(crepoDaySegment),
            };
          })}
        />
      );
    }
    case "BlocksCenteredProductHeroCan":
      return <CenteredProductHeroCan block={block} />;

    case "BlocksChatAssistantTeaser":
      return (
        <ChatAssistantTeaser
          botAnimationDescription={block.botAnimation?.description ?? null}
          botAnimationUrl={block.botAnimation?.url ?? null}
          bubbleText={block.bubbleText}
          ctaLink={block.ctaLink}
          ctaText={block.ctaText}
          definitionListItems={
            block.definitionListCollection?.items.filter(notNull) ?? []
          }
          definitionListTitle={block.definitionListTitle}
          heading={block.heading}
          introduction={
            <Markdown
              options={{ wrapper: Fragment }}
              children={block.introduction ?? ""}
            />
          }
          kicker={block.kicker}
        />
      );

    case "BlocksContactAssistantStage":
      return (
        <ContactAssistantStage
          name={block.name}
          title={block.title}
          description={block.description}
          linkText={block.linkText}
          linkUrl={block.linkUrl}
          backgroundImageUrl={block.backgroundImage?.url ?? null}
        />
      );

    case "BlocksCompanyStage":
      return <CompanyStage block={block} />;

    case "BlocksCompanyTeaser":
      return (
        <CompanyTeaser
          imageUrl={block.image?.url ?? null}
          preHeading={block.preHeading}
          title={block.title}
          description={block.description}
          linkUrl={block.linkUrl}
          linkText={block.linkText}
        />
      );

    case "BlocksContactForm":
      return <ContactForm block={block} />;

    case "BlocksContactFormStage":
      return <ContactFormStage block={block} />;

    case "BlocksCustomScript":
      return <CustomScript block={block} />;

    case "BlocksDisclosureList":
      return (
        <DisclosureList
          title={block.heading}
          introduction={
            block.text?.json && documentToReactComponents(block.text.json)
          }
          items={
            block.disclosureListItemsCollection?.items
              .filter(notNullish)
              .map((item) => ({
                heading: item.heading,
                text:
                  item.text?.json && documentToReactComponents(item.text.json),
              })) ?? []
          }
        />
      );

    case "BlocksExperienceWorb":
      return (
        <ExperienceWorb
          title={block.title}
          timelineItems={block.dotcomContent.map((item) => {
            const firstFeaturedMedia = item.featuredMedia
              ?.filter(notNullish)
              .find(isImage);

            const image =
              firstFeaturedMedia?.imageEssence?.__typename === "CloudinaryImage"
                ? firstFeaturedMedia.imageEssence.imageURL
                : firstFeaturedMedia?.imageSrc;

            return {
              contextualTitle: item.contextualTitle?.featured?.text ?? null,
              disciplines:
                item.disciplines
                  ?.map((discipline) => discipline?.title?.text)
                  .filter(notNullish) ?? [],
              title: item.title?.text ?? null,
              url: item.url?.href ?? null,
              image: image ?? null,
              contentType: item.__typename,
            };
          })}
        />
      );

    case "BlocksFlyingChocolateBanner":
      return (
        <FlyingChocolateBanner
          legalNotice={block.legalNotice}
          flyingChocolateBannerDescription={
            block.flyingChocolateBannerDescription?.json &&
            documentToReactComponents(
              block.flyingChocolateBannerDescription.json,
            )
          }
          backgroundImageDescription={
            block.backgroundImage?.description ?? null
          }
          backgroundImageUrl={block.backgroundImage?.url ?? null}
        />
      );

    case "BlocksFlyingChocolateNutritionFacts":
      return (
        <FlyingChocolateNutritionFacts
          kicker={block.kicker}
          title={block.title}
          artworkUrl={block.artwork?.url ?? null}
          artworkDescription={block.artwork?.description ?? null}
          legalNotice={block.legalNotice}
          content={
            block.flyingChocolateNutritionDescription?.json &&
            documentToReactComponents(
              block.flyingChocolateNutritionDescription.json,
            )
          }
        />
      );

    case "BlocksFlyingChocolateStage":
      return (
        <FlyingChocolateStage
          title={block.title}
          description={block.description}
          heroArtworkUrl={block.heroArtwork?.url ?? null}
          heroArtworkDescription={block.heroArtwork?.description ?? null}
        />
      );

    case "BlocksGenericSection":
      return (
        <GenericSection
          kicker={block.kicker}
          heading={block.heading}
          introduction={
            block.introduction?.json
              ? documentToReactComponents(block.introduction.json)
              : null
          }
          panelsCollection={
            block.panelsCollection?.items
              .map((item) => {
                if (!item) {
                  return null;
                }

                switch (item.__typename) {
                  case "Generic2ColTextMedia":
                    return {
                      __typename: item.__typename,
                      heading: item.heading,
                      direction: item.direction,
                      text:
                        item.text?.json &&
                        documentToReactComponents(item.text?.json),
                      media:
                        (item.media && {
                          description: item.media.description,
                          contentType: item.media.contentType,
                          url: item.media.url,
                        }) ??
                        null,
                    };
                  case "GenericRichText":
                    return {
                      __typename: item.__typename,
                      text:
                        item.text?.json &&
                        documentToReactComponents(item.text?.json),
                    };
                  case "GenericTextDisclosure":
                    return {
                      __typename: item.__typename,
                      readMoreLabel: item.readMoreLabel,
                      readLessLabel: item.readLessLabel,
                      text:
                        item.text?.json &&
                        documentToReactComponents(item.text?.json),
                    };
                  default:
                    return null;
                }
              })
              .filter(notNullish) ?? null
          }
        />
      );

    case "BlocksGenericStage":
      return (
        <GenericStage
          kicker={block.kicker}
          heading={block.heading}
          headingSize={block.headingSize}
          introduction={
            block.introduction?.json &&
            documentToReactComponents(block.introduction.json)
          }
        />
      );

    case "BlocksNutritionInformation":
      return <NutritionInformation block={block} />;

    case "BlocksHighlightIntro":
      return <HighlightIntro block={block} />;

    case "BlocksHighlightProgressCarousel":
      return <HighlightProgressCarousel block={block} />;

    case "BlocksHighlightsStage":
      return <HighlightsStage block={block} highlights={block.highlights} />;

    case "BlocksImageGallery":
      return (
        <ImageGallery
          title={block.title}
          variant={block.variant}
          mediaCollection={
            block.mediaCollection?.items
              .map((item) => ({
                caption: item?.caption ?? null,
                imageUrl: item?.image?.url ?? null,
              }))
              .filter(notNullish) ?? null
          }
        />
      );

    case "BlocksIngredient":
      return (
        <Ingredient block={block} fiveColumns={isBlocksQaStage(blocks[0])} />
      );

    case "BlocksIngredientsStage":
      return <IngredientsStage block={block} />;

    case "BlocksLifecycleTeaser":
      return hasInteractiveHero ? (
        <InteractiveHeroLifecycleTeaser block={block} />
      ) : (
        <LifecycleTeaser block={block} />
      );

    case "BlocksMissionStatement":
      return (
        <MissionStatement
          kicker={block.kicker}
          heading={block.heading}
          statement={block.statement}
          introduction={
            block.introduction?.json &&
            documentToReactComponents(block.introduction.json)
          }
          disclosureText={
            block.disclosureText?.json &&
            documentToReactComponents(block.disclosureText.json)
          }
          readMoreLabel={block.readMoreLabel}
          readLessLabel={block.readLessLabel}
        />
      );

    case "BlocksMomentsStage":
      return (
        <MomentsStage
          kicker={block.kicker}
          title={block.title}
          subTitle={block.subTitle}
          introductionKicker={block.introductionKicker}
          introductionTitle={block.introductionTitle}
          introductionText={
            block.introductionText?.json
              ? documentToReactComponents(block.introductionText?.json)
              : null
          }
          imageUrl={block.image?.url ?? null}
        />
      );

    case "BlocksNewLifecycle":
      return (
        <NewLifecycle
          stage={
            block.stage
              ? {
                  kicker: block.stage.kicker ?? null,
                  title: block.stage.title ?? null,
                  text: block.stage.text ?? null,
                  textLong: block.stage.textLong ?? null,
                  videoText: block.stage.videoText ?? null,
                  videoPlayerId: block.stage.video?.videoPlayerId
                    ? block.stage.video.videoPlayerId
                    : null,
                  rail: block.stage.rail
                    ? {
                        title: block.stage.rail.title ?? null,
                        text: block.stage.rail.text ?? null,
                        cardsCollection:
                          block.stage.rail.cardsCollection?.items
                            .filter(notNullish)
                            .map((item) => ({
                              title: item.title ?? null,
                              description: item.description ?? null,
                              link: item.link ?? null,
                              linkText: item.linkText ?? null,
                              media: {
                                contentType: item.media?.contentType ?? null,
                                url: item.media?.url ?? null,
                                description: item.media?.description ?? null,
                              },
                            })) ?? null,
                      }
                    : null,
                  teaserCard: block.stage.teaserCard
                    ? (() => {
                        switch (block.stage.teaserCard.__typename) {
                          case "BlocksProductTeaserCard":
                            return {
                              __typename: "BlocksProductTeaserCard",
                              name: block.stage.teaserCard.name,
                              title: block.stage.teaserCard.title,
                              description: block.stage.teaserCard.description,
                              disclaimer: block.stage.teaserCard.disclaimer,
                              centerDisclaimer:
                                block.stage.teaserCard.centerDisclaimer,
                              linkText: block.stage.teaserCard.linkText,
                              linkUrl: block.stage.teaserCard.linkUrl,
                              backgroundColor:
                                block.stage.teaserCard.backgroundColor,
                              smallImage: block.stage.teaserCard.smallImage,
                              productType: block.stage.teaserCard.name,
                              backgroundImageUrl:
                                block.stage.teaserCard.backgroundImage?.url ??
                                null,
                              mobileBackgroundImageUrl:
                                block.stage.teaserCard.mobileBackgroundImage
                                  ?.url ?? null,
                              logoHeadingUrl:
                                block.stage.teaserCard.logoHeading?.url ?? null,
                              imageUrl:
                                block.stage.teaserCard.image?.url ?? null,
                            };
                          case "BlocksProductHighlightPanel":
                            return {
                              __typename: "BlocksProductHighlightPanel",
                              kicker: block.stage.teaserCard.kicker,
                              headline: block.stage.teaserCard.headline,
                              copy: block.stage.teaserCard.copy,
                              disclaimer: block.stage.teaserCard.disclaimer,
                              ctaText: block.stage.teaserCard.ctaText,
                              ctaLink: block.stage.teaserCard.ctaLink,
                              legalText: block.stage.teaserCard.legalText,
                              productImage: block.stage.teaserCard.productImage
                                ? {
                                    description: block.stage.teaserCard.kicker,
                                    contentType: block.stage.teaserCard.kicker,
                                    url: block.stage.teaserCard.kicker,
                                  }
                                : null,
                            };
                        }
                      })()
                    : null,
                }
              : null
          }
          phasesCollection={
            block.phasesCollection?.items?.filter(notNullish).map((item) => {
              switch (item.__typename) {
                case "LifecyclePhase":
                  return {
                    __typename: "LifecyclePhase" as const,
                    id: item.sys.id,
                    title: item.title,
                    quote: item.quote,
                    readMoreText: item.readMoreText,
                    readLessText: item.readLessText,
                    text: item.text,
                    cardsHeadline: item.cardsHeadline,
                    videoUrl: item.video?.url ?? null,
                    cardsCollection: item.cardsCollection
                      ? item.cardsCollection.items
                          .filter(notNullish)
                          .map((curr) => ({
                            largeText: curr.largeText,
                            smallBodyText: curr.smallBodyText?.json
                              ? documentToReactComponents(
                                  curr.smallBodyText.json,
                                )
                              : null,
                            anchorSection: curr.anchorSection?.sys
                              ? { id: curr.anchorSection.sys.id }
                              : null,
                          }))
                      : null,
                    phaseBlocksCollection:
                      item.phaseBlocksCollection?.items
                        .filter(notNullish)
                        .map((block) => {
                          switch (block.__typename) {
                            case "PhaseImage":
                              return {
                                __typename: "PhaseImage" as const,
                                id: block.sys.id,
                                kicker: block.kicker,
                                image: block.image
                                  ? {
                                      description: block.image?.description,
                                      url: block.image?.url,
                                    }
                                  : null,
                              } satisfies PhaseImageType;
                            case "PhasePanelColumns":
                              return {
                                __typename: "PhasePanelColumns" as const,
                                id: block.sys.id,
                                kicker: block.kicker,
                                panelsCollection:
                                  block.panelsCollection?.items?.filter(
                                    notNullish,
                                  ) ?? null,
                              } satisfies PhasePanelColumnsType;
                            case "PhaseSideImageColumns":
                              return {
                                __typename: "PhaseSideImageColumns" as const,
                                id: block.sys.id,
                                sideImageColumnsCollection:
                                  block.sideImageColumnsCollection?.items
                                    .filter(notNullish)
                                    .map((panel) => ({
                                      ...panel,
                                      bodyText: panel.bodyText?.json
                                        ? contentfulRichTextToSideImageColumns(
                                            panel.bodyText.json,
                                          )
                                        : null,
                                    })) ?? null,
                              } satisfies PhaseSideImageColumnsType;
                            case "PhaseText":
                              return {
                                __typename: "PhaseText" as const,
                                id: block.sys.id,
                                text: block.text
                                  ? contentfulPhaseTextToReactNode(
                                      block.text.json,
                                    )
                                  : null,
                              } satisfies PhaseTextType;
                            default:
                              return null;
                          }
                        })
                        .filter(notNullish) ?? null,
                  };
                case "LifecycleCta":
                  return {
                    __typename: "LifecycleCta",
                    title: item.title,
                    buttonText: item.buttonText,
                    quizLinkUrl: item.quizLinkUrl,
                    quizLinkText: item.quizLinkText,
                    videoUrl: item.video?.url ?? null,
                  };
              }
            }) ?? null
          }
        />
      );

    case "BlocksPcsMoodboard":
      return <MoodboardTiles block={block} tiles={block.tiles} />;

    case "BlocksOccasionStack":
      return (
        <OccasionStack
          block={block}
          dotcomContent={block.dotcomContent}
          animated={scrollTransitionsEnabled}
        />
      );

    case "BlocksPhotoTiles":
      return <PhotoTiles block={block} />;

    case "BlocksPlaceholder":
      return <Placeholder block={block} />;

    case "BlocksProductTeaserCard":
      if (block.productType === "organics") {
        return (
          <OrganicsTeaserCard
            name={block.name}
            description={block.description}
            linkText={block.linkText}
            linkUrl={block.linkUrl}
            backgroundColor={block.backgroundColor}
            backgroundImage={block.backgroundImage}
            logoHeading={block.logoHeading}
            image={block.image}
          />
        );
      } else {
        return (
          <ProductTeaserCard
            name={block.name}
            title={block.title}
            description={block.description}
            disclaimer={block.disclaimer}
            centerDisclaimer={block.centerDisclaimer}
            linkText={block.linkText}
            linkUrl={block.linkUrl}
            backgroundColor={block.backgroundColor}
            smallImage={block.smallImage}
            productType={block.productType}
            backgroundImage={block.backgroundImage}
            mobileBackgroundImage={block.mobileBackgroundImage}
            logoHeading={block.logoHeading}
            image={block.image}
          />
        );
      }

    case "BlocksProductDescription":
      return <ProductDescription block={block} />;

    case "BlocksStepsCarousel":
      return (
        <StepsCarousel
          kicker={block.kicker}
          heading={block.heading}
          introduction={
            block.introduction?.json
              ? documentToReactComponents(block.introduction.json)
              : null
          }
          slidesCollectionItems={
            block.slidesCollection?.items.map((item) => ({
              heading: item?.heading ?? null,
              text: item?.text?.json
                ? documentToReactComponents(item.text.json)
                : null,
              media: item?.media
                ? {
                    description: item?.media?.description,
                    url: item?.media?.url,
                    width: item?.media?.width,
                    contentType: item?.media?.contentType,
                  }
                : null,
            })) ?? null
          }
        />
      );

    case "BlocksProductHeroCan":
      return <ProductHeroCan block={block} />;

    case "BlocksProductHighlightPanel":
      return (
        <ProductHighlightPanel
          animated={scrollTransitionsEnabled}
          kicker={block.kicker}
          headline={block.headline}
          copy={block.copy}
          disclaimer={block.disclaimer}
          ctaText={block.ctaText}
          ctaLink={block.ctaLink}
          legalText={block.legalText}
          productImage={block.productImage}
        />
      );

    case "BlocksProductRail": {
      return (
        <ProductRail
          id={block.sys.id}
          animated={scrollTransitionsEnabled}
          intro={
            block.intro?.json && documentToReactComponents(block.intro.json)
          }
          vendorLinksMikmakIds={block.vendorLinksReference?.mikmakIds ?? null}
          vendorLinksLabel={block.vendorLinksReference?.label ?? null}
          vendorLinks={
            block.vendorLinksReference?.linksCollection?.items
              .filter(notNullish)
              .map((item) => ({
                icon: item.icon,
                label: item.label,
                url: item.url,
              })) ?? []
          }
          products={
            block.productsCollection?.items.filter(notNullish).map((item) => ({
              id: item.sys.id,
              backgroundColor: item?.backgroundColor,
              headingTextColor: item.headingTextColor,
              theme: item.theme,
              brand: item.brand,
              canArtworkDescription: item.canArtwork?.description ?? null,
              canArtworkUrl: item.canArtwork?.url ?? null,
              linkText: item.linkText,
              linkUrl: item.linkUrl,
              name: item.name,
            })) ?? []
          }
          anchorScrollText={block.anchorScrollText}
          largeHeading={block.largeHeading ?? false}
          headingGradient={block.headingGradient ?? false}
          preHeading={block.preHeading}
          heading={block.heading}
          headingElement={
            /* First block on the page should use "h1", otherwise "h2". */
            blocks[0] === block ? "h1" : "h2"
          }
          productsWithStamp={block.productsWithStamp ?? false}
          buyOnAmazonText={block.buyOnAmazonText}
          buyOnAmazonUrl={block.buyOnAmazonUrl}
          ctaButtonLabel={block.ctaButtonLabel}
          ctaButtonUrl={block.ctaButtonUrl}
        />
      );
    }

    case "BlocksProductStage":
      return <ProductStage block={block} />;

    /*
    case "BlocksFaqArticle":
      return (
        <FaqArticle
          title={block.title}
          titleGraphicUrl={block.titleGraphic?.url}
          categoryTitle={block.productCategory?.title ?? null}
          categoryUrl={block.productCategory?.linkUrl ?? null}
          categorySummaryIconUrl={block.summaryIcon?.url ?? null}
          content={block.body && documentToReactComponents(block.body.json)}
          contentHtml={block.body && documentToHtmlString(block.body.json)}
          withSugarfreeDietaryMark={
            block.productCategory?.internalName?.includes(
              "red-bull-sugarfree",
            ) ?? false
          }
        />
      );
    */

    case "BlocksQaArticle":
      return (
        <QaArticle
          title={block.title}
          categoryTitle={block.productCategory?.title ?? null}
          content={block.body && documentToReactComponents(block.body.json)}
          contentHtml={block.body && documentToHtmlString(block.body.json)}
          withSugarfreeDietaryMark={
            block.productCategory?.internalName?.includes(
              "red-bull-sugarfree",
            ) ?? false
          }
        />
      );

    case "BlocksQaMoreTeaser":
      return (
        <QaMoreTeaser
          title={block.title}
          backgroundImageUrl={block.backgroundImage?.url}
          categories={
            block.qaCategoriesCollection?.items
              .filter(notNullish)
              .map((item) => ({
                id: item.sys.id,
                name: item.name,
                title: item.title,
                linkText: item.linkText,
                linkUrl: item.linkUrl,
                imageUrl: item.image?.url,
                imageDescription: item.image?.description,
                summaryIconUrl: item.summaryIcon?.url,
              })) ?? []
          }
        />
      );

    case "BlocksQaSummary": {
      return (
        <QaSummary
          title={block.title}
          faqItems={
            block.questionsCollection?.items.filter(notNullish).map((item) => {
              const answerDocument = item.summaryBody?.json || item.body?.json;

              return {
                question: item.summaryTitle || item.title,
                answer:
                  answerDocument && documentToReactComponents(answerDocument),
                titleGraphic: item.summaryIcon?.url ?? null,
                url: item.pageUrl,
                readMoreLabel: null,
              };
            }) ?? []
          }
        />
      );
    }

    case "BlocksQaStage": {
      const qaArticleBlock = blocks.find(isBlocksQaArticle);
      return (
        <QaStage
          name={block.name}
          title={block.title}
          description={block.description}
          linkUrl={block.linkUrl}
          linkText={block.linkText}
          backgroundImage={block.backgroundImage?.url}
          qaCategoryText={block.qaCategoryText}
          qaFilterText={block.qaFilterText}
          qaResultPluralText={block.qaResultPluralText}
          qaResultSingularText={block.qaResultSingularText}
          qaResetText={block.qaResetText}
          qaInputText={block.qaInputText}
          keywords={
            block.qaKeywordsCollection?.items
              .filter(notNullish)
              .map((item) => ({
                name: item.name,
                keyword: item.keyword,
                linkUrl: item.linkUrl,
                linkText: item.linkText,
                ingredient: item.ingredient && {
                  title: item.ingredient.title,
                  content:
                    item.ingredient.body &&
                    documentToReactComponents(item.ingredient.body.json),
                },
              })) ?? []
          }
          categories={
            block.qaCategoriesCollection?.items
              .filter(notNullish)
              .map((item) => ({
                id: item.sys.id,
                name: item.name,
                title: item.title,
                linkText: item.linkText,
                linkUrl: item.linkUrl,
                imageUrl: item.image?.url,
                imageDescription: item.image?.description,
              })) ?? []
          }
          answers={
            block.qaAnswers?.answersCollection?.items
              .filter(notNullish)
              .map((item) => ({
                ...item,
                productCategoryId: item.productCategory?.sys.id,
                productCategoryTitle: item.productCategory?.title,
                rawContent:
                  item.body?.json && documentToPlainTextString(item.body.json),
              })) ?? []
          }
          adjacentArticleCategoryId={qaArticleBlock?.productCategory?.sys.id}
          /**
           * If there is an adjacent article we don't want to push it too far
           * below the fold, so we will collapse the answers. If there's no
           * adjacent article then we might as well just show all the articles
           * by default.
           */
          initialShowAnswers={!qaArticleBlock}
        />
      );
    }

    case "BlocksQuote":
      return (
        <Quote
          buttonLabel={block.buttonLabel ?? null}
          productRelatedQuote={
            block.dotcomContent.productRelatedQuote?.text ?? null
          }
          title={block.dotcomContent.title?.text ?? null}
          url={block.dotcomContent.url?.href ?? null}
          featuredImageUrl={
            findFirstImageUrlFromFeaturedMedia(
              block.dotcomContent.featuredMedia,
            ) ?? null
          }
          disciplines={
            block.dotcomContent.disciplines?.filter(notNullish) ?? null
          }
        />
      );

    case "BlocksRecipeSlider":
      return <RecipeSlider block={block} animated={scrollTransitionsEnabled} />;

    case "BlocksSupportCta":
      return <SupportCta block={block} animated={scrollTransitionsEnabled} />;

    case "BlocksSustainabilityQuiz": {
      return (
        <SustainabilityQuiz
          questionsBlocks={
            block.questions?.questionsBlocksCollection?.items.filter(
              notNullish,
            ) ?? []
          }
          questionsText={{
            questionKicker: block.questions?.questionKicker ?? null,
            answerKicker: block.questions?.answerKicker ?? null,
            nextButtonText: block.questions?.nextButtonText ?? null,
            restartLinkText: block.questions?.restartLinkText ?? null,
            restartLinkUrl: block.questions?.restartLinkUrl ?? null,
            labelCorrect: block.questions?.labelCorrect ?? null,
            labelWrong: block.questions?.labelWrong ?? null,
            labelTimesUp: block.questions?.labelTimesUp ?? null,
          }}
          endScreenText={{
            totalTimeLabel: block.end?.totalTimeLabel ?? null,
            completionGood: block.end?.completionGood ?? null,
            completionOk: block.end?.completionOk ?? null,
            completionBad: block.end?.completionBad ?? null,
            completionText: block.end?.completionText ?? null,
            moreButtonText: block.end?.moreButtonText ?? null,
            moreButtonUrl: block.end?.moreButtonUrl ?? null,
            retakeButtonText: block.end?.retakeButtonText ?? null,
            retakeButtonUrl: block.end?.retakeButtonUrl ?? null,
            videoUrl: block.end?.video?.url ?? null,
          }}
        />
      );
    }

    case "BlocksUsefulSourcesRail":
      return <UsefulSourcesRail block={block} />;

    case "BlocksVideo":
      return (
        <Video
          title={block.title}
          body={
            block.body?.json
              ? documentToReactComponents(block.body?.json)
              : null
          }
          videoPlayerId={block.videoPlayerId}
        />
      );

    case "BlocksWorbCarousel":
      return (
        <WorbCarousel
          block={block}
          dotcomContent={block.dotcomContent}
          animated={scrollTransitionsEnabled}
        />
      );

    case "BlocksWorbRail":
      return (
        <WorbRail
          dotcomContent={block.dotcomContent}
          animated={scrollTransitionsEnabled}
        />
      );

    case "BlocksYearMap":
      return <YearMap block={block} />;

    case "BlocksinteractiveHero":
      return <InteractiveHero block={block} />;

    case "BlocksStayTuned":
      return <StayTuned block={block} />;

    case "BlocksSpeakUpCta":
      return (
        <SpeakUpCta
          title={block.title ?? ""}
          description={
            block.description?.json &&
            documentToReactComponents(block.description.json)
          }
          dropdownOptions={
            block.dropdownOptionsCollection?.items
              .filter(notNullish)
              .map((item) => ({
                label: item?.label ?? "",
                intent: item?.intent ?? "",
              })) ?? []
          }
          dropdownPlaceholderText={block.dropdownPlaceholderText ?? ""}
          callToActionText={block.callToActionText ?? ""}
          callToActionUrl={block.callToActionUrl ?? ""}
          questionOrText={block.questionOrText ?? ""}
          questionHeading={block.questionHeading ?? ""}
          questionText={
            block.questionText?.json &&
            documentToReactComponents(block.questionText.json)
          }
          questionCallToActionLabel={block.questionCallToActionLabel ?? ""}
          questionCallToActionUrl={block.questionCallToActionUrl ?? ""}
        />
      );

    default:
      return null;
  }
};

function findFirstImageUrlFromFeaturedMedia(
  featuredMedia: PersonProfile["featuredMedia"],
) {
  const image = featuredMedia?.filter(notNull).find(isImage);

  return image?.imageEssence?.__typename === "CloudinaryImage"
    ? image.imageEssence.imageURL
    : image?.imageSrc;
}
